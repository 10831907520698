export const RQ_CREATE_BUDGET_TYPE_API = {
  url: 'v2/budget-type/create',
  method: 'POST',
  auth: true,
};

export const RQ_GET_BUDGET_TYPE_LIST_API = {
  url: 'v2/budget-type/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_BUDGET_TYPE_BY_ID_API = {
  url: 'v2/budget-type/findById/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_BUDGET_TYPE_ALL_FIELDS_API = {
  url: 'v2/budget-type/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SEARCH_BUDGET_TYPE_API = {
  url: 'v2/budget-type/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_SEARCH_MY_BUDGET_API = {
  url: 'v2/wallet/summary?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_SEARCH_MY_TEAM_BUDGET_API = {
  url: 'v2/wallet/team/summary/<%= locationId %>?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_MY_BUDGET_TYPE_API = {
  url: 'v2/budget-type/for-user/<%= userId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_BUDGET_YEAR_LOCATION_BUDGET_BRAND_API = {
  url: 'v2/wallet-balance/balance/<%= year %>/<%= locationId %>/<%= budget %>/<%= brand %>',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_BUDGET_FOR_EVENT_CONFIGURATIONS = {
  url: 'v2/budget-type/for-event-configuration',
  method: 'GET',
  auth: true,
};
